<template>
    <div>
        <v-main fluid class="mt-n6">
            <div>
                <v-form ref="form"
                        v-model="valid"
                        class="w-100">

                    <v-container v-show="GetWhatToShow == 'view'" class="w-100 pa-8">
                        <kview form="gelenteklif"
                               :viewname="GetViewName"
                               @rowclicked="ViewClicked($event, 'view')"></kview>
                    </v-container>
                    <v-container v-show="GetWhatToShow != 'view'" fluid class="pa-8">
                        <v-btn text tile color="primary" @click="BackToView">
                            <v-icon left>mdi-arrow-left</v-icon>{{$t("AL_Back")}}
                        </v-btn>
                        <v-row>
                            <v-col class="pb-0 mb-0">
                                <dynoform header="Firmam�z Teklifleri"
                                          @change="UpdateFormField($event, 'formdata')"
                                          @changeGrid="UpdateExtData($event, $event.tableName)"
                                          @click="FormButtonClicked($event)"
                                          :attributes="GetFormAttributes"
                                          @SearchInputChanged="SearchInputChanged($event, 'formdata')"
                                          @RowButtonClicked="RowButtonClicked($event)"
                                          :tabname="Math.random().toString()"></dynoform>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </div>
            <v-dialog v-model="actionBarWait"
                      persistent>
                <v-card color="transparent"
                        dark class="py-5">
                    <v-card-text class="text-center py-5">
                        <v-progress-circular indeterminate
                                             :size="50"
                                             color="white"></v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-footer class="grey lighten-3 pa-3" inset app padless>
                <v-progress-circular v-if="actionBarWait" :size="20" :width="2" rounded indeterminate color="primary" class="my-2 mr-2"></v-progress-circular>
                <v-toolbar-title v-if="actionBarWait" class="primary--text overline">{{$t("IM_YourTransactionIsInProgress")}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-show="GetWhatToShow != 'view'" v-for="(actionInfo, index) in GetFormAction"
                       :loading="actionBarWait"
                       @click="ApprovedAction(actionInfo)"
                       depressed
                       class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize"
                       :class="index == GetFormAction.length - 1 ? 'primary' : 'secondary'"
                       :key="actionInfo.action">
                    {{ $t(actionInfo.label) }}
                </v-btn>
            </v-footer>

            <v-dialog v-model="showDetailDialog" class="pb-0 mb-0" width="800" height="600">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col class="pb-0 mb-0" style="height: 600px">
                                <dynoform header="Firmam�z Teklifleri"
                                          @change="UpdateFormField($event, 'dynamicFormData')"
                                          @changeGrid="UpdateExtData($event, 'dynamicFormData')"
                                          @click="FormButtonClicked($event)"
                                          @SearchInputChanged="SearchInputChanged($event, 'dynamicFormData')"
                                          @RowButtonClicked="RowButtonClicked($event)"
                                          :tabname="Math.random().toString()"
                                          :tableName="'dynamicFormData'"
                                          :formInfo="DynamicFormInfo"></dynoform>
                            </v-col>

                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                               @click="showDetailDialog = false"
                               depressed
                               class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize secondary">
                            Kapat
                        </v-btn>
                        <v-btn 
                               @click="UpdateDynamicForm()"
                               depressed
                               class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize primary">
                            Kaydet
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-main>

    </div>
</template>

<style>
    .grey-lighten-4 {
        background-color: #f5f5f5;
    }

    .v-dialog {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
</style>

<script>

    import Utilities from '@/Utilities.js'
    import Vue from 'vue';
    import Vuex from 'vuex';
    Vue.use(Vuex);

    var sal_kalem_detay = {
        tableName: 'sal_kalem_detay',
        columns: {
            hartipiad: {
                type: 'text',
                name: 'hartipiad',
                label: 'Category',
                edit: '',
                width: 15
            },
            ad: {
                type: 'text',
                name: 'ad',
                label: 'FL_ItemName',
                edit: '',
                width: 15
            },
            notlar: {
                type: 'text',
                name: 'notlar',
                label: 'FL_ShortDescription',
                edit: '01X2',
                width: 15
            },
            talepmiktar:
            {
                type: 'number',
                label: 'FL_AmountRequested',
                edit: '01X2',
                name: 'talepmiktar',
                width: 10,
                validateMin: 1,
            },

            talepbirim: {
                type: 'text',
                label: 'FL_QuantityType',
                edit: '',
                name: 'talepbirim',
                width: 5
            },
            alisfiyat: {
                type: 'number',
                label: 'FL_UnitPriceKDV',
                edit: '01X2',
                name: 'alisfiyat',
                width: 15
            },

            doviz: {
                type: 'select',
                label: 'FL_Currency',
                edit: '01X2',
                name: 'doviz',
                width: 10,
                options: [],
                validateMin: 1,
            },
            dovizkur: {
                type: 'hidden',
                label: 'FL_CurrencyRate',
                edit: '',
                name: 'dovizkur',
                width: 15
            },
            copy: {
                type: 'button',
                label: 'FL_Kopyala',
                name: 'copy',
                edit: '01X2',
                width: 10,
            },
            delete: {
                type: 'button',
                label: 'FL_Sil',
                name: 'delete',
                edit: '01X2',
                width: 5,
            },
            button: {
                type: 'hidden',
                label: 'FL_DetailInformation',
                name: 'button',
                edit: '01X2',
                width: 5,
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
            rowid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'rowid',
                width: 0
            },
            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            doviztip: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'doviztip',
                width: 0
            },
            taleptutardoviz: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'taleptutardoviz',
                width: 0
            },
            taleptutar: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'taleptutar',
                width: 0
            },
            teslimsuresi:
            {
                type: 'hidden',
                label: 'FL_DeliveryDuration',
                edit: '01X2',
                name: 'teslimsuresi',
                width: 10
            },
            garanti:
            {
                type: 'hidden',
                label: 'FL_WarrantyDuration',
                edit: '01X2',
                name: 'garanti',
                width: 10
            },
            smartid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'smartid',
                width: 0
            }
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                talepmiktar: { items: [], error: false, readonly: false, hidden: false },
                talepbirim: { items: [], error: false, readonly: false, hidden: false },
                alisfiyat: { items: [], error: false, readonly: false, hidden: false },
                ad: { items: [], error: false, readonly: false, hidden: false },
                doviz: { items: [], error: false, readonly: false, hidden: false },

            }

        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'gelenteklif_kalemler'
        },
        parameters: ['**'],
        data: [],
        maxLen: 100,
        loaded: false,
        loading: false
    };


    var sal_kurlar = {
        tableName: 'sal_kurlar',
        columns: {
            doviz: {
                type: 'text',
                name: 'doviz',
                label: 'FL_Currency',
                edit: '',
                validateMin: 1,
            },
            tip:
            {
                type: 'select',
                name: 'tip',
                label: 'FL_CurrencyRateType',
                group: 2,
                sira: 15,
                col: '6',
                edit: '01X2',
                options: [
                    { text: 'OP_PleaseSelect', value: '' }
                ],
            },
            dovizkur: {
                type: 'number',
                name: 'dovizkur',
                label: 'FL_CurrencyRate',
                edit: '01X2',
                validateMin: 1,
                readonly: true
            },
            tarih: {
                type: 'hidden',
                name: 'tarih',
                label: '',
                edit: '',
                width: 0
            },
            form: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'form',
                width: 0
            },
            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
            rowid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'rowid',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                dovizkur: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        initialColumns: {
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'Gelenteklif_Kurlar'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var teklif_rank = {
        tableName: 'teklif_rank',
        columns: {
            firmaad: {
                type: 'text',
                name: 'firmaad',
                label: 'FL_CompanyTitle',
                edit: '',
                width: 80
            },
            rank:
            {
                type: 'text',
                label: 'FL_Rank',
                edit: '',
                name: 'rank',
                width: 20,
                validateMin: 1,
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            }
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
            }

        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'gelenteklif_rank'
        },
        parameters: ['**'],
        data: [],
        maxLen: 100,
        loaded: false,
        loading: false
    };

    var gelenteklif_tedarikci_files = {
        tableName: 'gelenteklif_tedarikci_files',
        label: 'AdditionalDocuments',
        columns: {
            aciklama: {
                type: 'text',
                name: 'aciklama',
                label: 'FileDescription',
                edit: '01X2',
                template: '',
                validateMin: 1,
            },
            url: {
                type: 'file',
                name: 'url',
                label: 'FileSelection',
                edit: '01X2',
                template: '',
                headerhint: 'FileSelectionHint',
                validateMin: 1,
            },
            tarih: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'tarih',
                width: 0
            },
            kullanici: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'kullanici',
                width: 0
            },
            form: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'form',
                width: 0
            },
            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
            rowid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'rowid',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: true, hidden: true },
                form: { items: [], error: false, readonly: true, hidden: true },
                formid: { items: [], error: false, readonly: true, hidden: true },
                kullanici: { items: [], error: false, readonly: true, hidden: true },
                tarih: { items: [], error: false, readonly: true, hidden: true },
                aciklama: { items: [], error: false, readonly: false, hidden: false },
                url: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'gelenteklif_tedarikci_files'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var form_files = {
        tableName: 'form_files',
        columns: {
            tarih: {
                type: 'date',
                name: 'ad',
                label: 'FL_Date',
                edit: '',
                width: 10
            },
            fieldname:
            {
                type: 'text',
                label: 'FL_FileType',
                edit: '',
                name: 'fieldname',
                width: 10
            },
            url: {
                type: 'button',
                label: 'FL_ShowFile',
                name: 'url',
                edit: '2',
                width: 10
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                talepmiktar: { items: [], error: false, readonly: false, hidden: false },
                talepbirim: { items: [], error: false, readonly: false, hidden: false },
                alisfiyat: { items: [], error: false, readonly: false, hidden: false },
                ad: { items: [], error: false, readonly: false, hidden: false },
                doviz: { items: [], error: false, readonly: false, hidden: false },

            }

        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'gelenteklif_files'
        },
        parameters: ['**'],
        data: [],
        maxLen: 100,
        loaded: false,
        loading: false
    };

    var additional_fields = {
        tableName: 'additional_fields',
        columns: {
            Itemid: {
                type: 'hidden',
                name: 'Itemid',
                label: 'Itemid',
                edit: '',
                width: 55
            },
            PropName: {
                type: 'text',
                name: 'PropName',
                label: 'PropName',
                edit: '',
                width: 55
            },
            PropType: {
                type: 'text',
                name: 'PropType',
                label: 'PropType',
                edit: '',
                width: 55
            },
            PropValue: {
                type: 'text',
                name: 'PropValue',
                label: 'PropValue',
                edit: '2',
                width: 55,
                validateMin: 1,
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'gelenteklif_additional_fields'
        },
        parameters: ['**'],
        data: [],
        maxLen: 100,
        loaded: false,
        loading: false
    };

    var dynamic_formInfo = {
        tabs: [
            { order: 1, header: 'TH_Details' },

        ],
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'TH_Details'
            }
        ],
        Columns: {


        },
        InitialColumns: {
            id: {
                type: 'hidden',
                group: 1,
                sira: 10,
                name: 'id',
                label: 'FL_FormNo',
                edit: '',
                col: '6',
            },
        },
        form: 'dynamic_formInfo',
        parameters: [],
        attributes: {}
    };

    var GelenTeklif_formInfo = {
        tabs: [
            { order: 1, header: 'FN_Quatation' },

        ],
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'TH_FormInformation'
            },

            {
                tab: 1,
                order: 2,
                header: 'TH_ProposalConditions'
            },
            {
                tab: 1,
                order: 3,
                header: 'TH_ItemInformation'
            },
            {
                tab: 1,
                order: 4,
                header: 'FL_AdditionalFiles'
            },
            {
                tab: 1,
                order: 5,
                header: 'FL_Notes'
            },
            {
                tab: 1,
                order: 6,
                header: 'FL_TenderNonParticipationReason'
            },
        ],

        Columns: {
            id: {
                type: 'text',
                group: 1,
                sira: 10,
                name: 'id',
                label: 'FL_FormNo',
                edit: '',
                col: '6',
            },
            ihaleNoText: {
                type: 'text',
                group: 1,
                sira: 14,
                name: 'ihaleNoText',
                label: 'TenderNo',
                edit: '',
                col: '6',
            },
            durum: {
                type: 'text',
                group: 1,
                sira: 15,
                name: 'durum',
                label: 'FL_Status',
                edit: '',
                col: '6',
            },

            sontekliftar:
            {
                type: 'date',
                group: 1,
                sira: 30,
                name: 'sontekliftar',
                label: 'FL_LastResponseDate',
                edit: '',
                col: '6',
            },

            konu: {
                type: 'text',
                group: 1,
                sira: 35,
                name: 'konu',
                label: 'FL_Subject',
                edit: '',
                col: '6',
            },

            teklifsontarihoncesiacilabilirmi: {
                type: 'select',
                name: 'teklifsontarihoncesiacilabilirmi',
                label: 'FL_teklifsontarihoncesiacilabilirmi',
                edit: '',
                options: [
                ],
                group: 2,
                sira: 20,
                col: '6',
            },

            ihaleyontemi: {
                type: 'select',
                name: 'ihaleyontemi',
                label: 'FL_ihaleyontemi',
                edit: '',
                options: [
                ],
                group: 2,
                sira: 25,
                col: '6',
                hidden: false
            },

            sontarih:
            {
                type: 'date',
                group: 2,
                sira: 30,
                name: 'sontarih',
                label: 'FL_LastDate',
                edit: '',
                col: '3',
            },
            sonsaat:
            {
                type: 'text',
                group: 2,
                sira: 35,
                name: 'sonsaat',
                label: 'FL_LastTime',
                edit: '',
                col: '3',
            },
            teslimatadresi: {
                type: 'textarea',
                group: 2,
                sira: 40,
                name: 'teslimatadresi',
                label: 'FL_AddressToDeliver',
                edit: '',
                col: '6',
                buttons: [],
                dontCompare: true
            },
            teslimtarihi:
            {
                type: 'date',
                group: 2,
                sira: 45,
                name: 'teslimtarihi',
                label: 'FL_DeliveryDate',
                edit: '',
                col: '6',
            },

            gecerliliktarihi:
            {
                type: 'date',
                group: 1,
                sira: 30,
                name: 'gecerliliktarihi',
                label: 'FL_GecerlilikTarihi',
                edit: '01X2',
                col: '6',
            },
            teklifRank:
            {
                type: 'text',
                group: 2,
                sira: 49,
                name: 'teklifRank',
                label: 'FL_teklifRank',
                edit: '',
                col: '6',
            },
            teklif_rank: {
                type: 'grid',
                group: 2,
                sira: 55,
                name: 'teklif_rank',
                label: 'TH_TeklifFirmalar',
                edit: '',
                col: '8',
                buttons: [],
                dontCompare: true,
                showselect: false,
                showaddrow: false,
                showExportToExcelButton: false,
            },
            sal_kalem_detay: {
                type: 'grid',
                group: 3,
                sira: 10,
                name: 'sal_kalem_detay',
                label: 'TH_QuatationItems',
                edit: '',
                col: '12',
                buttons: [],
                dontCompare: true,
                showselect: false,
                showaddrow:false,
            },
            aciklamalar: {
                type: 'textarea',
                group: 3,
                sira: 15,
                name: 'aciklamalar',
                label: 'FL_Comment',
                edit: '',
                col: '12',
                buttons: [],
                dontCompare: true
            },
            sal_kurlar: {
                type: 'grid',
                group: 3,
                sira: 20,
                name: 'sal_kurlar',
                label: 'FL_CurrencyRates',
                edit: '',
                col: '12',
                showselect: false,
                showExportToExcelButton: false,
                buttons: []
            },
            tedarikfile: {
                type: 'file',
                group: 4,
                sira: 25,
                name: 'tedarikfile',
                col: '6',
                edit: '02',
                label: 'TH_QuatationFileWithPrice'
            },
            tedarikfileWithoutPrice: {
                type: 'file',
                group: 4,
                sira: 25,
                name: 'tedarikfileWithoutPrice',
                col: '6',
                edit: '02',
                label: 'TH_QuatationFileWithoutPrice'
            },
            otherfiles: {
                type: 'file',
                group: 4,
                sira: 25,
                name: 'otherfiles',
                col: '6',
                edit: '02',
                label: 'TH_OtherFiles'
            },
            gelenteklif_tedarikci_files: {
                type: 'grid',
                group: 4,
                sira: 27,
                name: 'gelenteklif_tedarikci_files',
                label: 'AdditionalDocuments',
                edit: '01X2',
                col: '12',
                showselect: true,
                buttons: [],
                dontCompare: true,
                sort: 'disable',
                showexcelexport: false,
                hiddenlabel: true,
                showaddrow: true,
            },
            form_files: {
                type: 'grid',
                group: 4,
                sira: 30,
                name: 'form_files',
                label: 'TH_FileHistory',
                edit: '',
                col: '12',
                buttons: [],
                dontCompare: true,
                showselect: false,
                showaddrow: false,
            },
            oonaynot: {
                type: 'history',
                group: 5,
                sira: 25,
                name: 'oonaynot',
                col: '12',
                edit: '01X2',
                label: ''
            },
            teklifisteformno: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'teklifisteformno',
                width: 0
            },
            conversionid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'conversionid',
                width: 0
            },
            katilmayacak: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'katilmayacak',
                width: 0
            },

            ihalekatilmamaneden: {
                type: 'textarea',
                group: 6,
                sira: 10,
                name: 'ihalekatilmamaneden',
                label: 'FL_TenderNonParticipationReason',
                edit: '2',
                col: '12',
            },

        },
        form: 'gelenteklif',
        formid: 0,
        viewName: 'bekleyen',
        viewparameter: '',
        files: ['ek1file', 'tedarikfile', 'tedarikfileWithoutPrice', 'otherfiles', 'administrativeSpecificationFile', 'technicalSpecificationFile'],
        extdata: [sal_kalem_detay, additional_fields, form_files, teklif_rank, sal_kurlar, gelenteklif_tedarikci_files],
        actions: ['G�nder'],
        parameters: [],
        attributes:{}
    };

    //var detailView_formInfo = {
    //    tabs: [
    //        { order: 1, header: 'FN_DetailView' },

    //    ],
    //    groups: [
    //        {
    //            tab: 1,
    //            order: 1,
    //            header: 'TH_FormInformation'
    //        }
    //    ],

    //    Columns: {
    //        id: {
    //            type: 'hidden',
    //            group: 1,
    //            sira: 10,
    //            name: 'id',
    //            label: 'FL_FormNo',
    //            edit: '',
    //            col: '6',
    //        },
    //        PropValue: {
    //            type: 'text',
    //            group: 1,
    //            sira: 15,
    //            name: 'PropValue',
    //            label: 'FL_Status',
    //            edit: '',
    //            col: '6',
    //        },
    //    },
    //    form: 'gelenteklif',
    //    formid: 0,
    //    viewName: 'tumu',
    //    viewparameter: '',
    //    files: [],
    //    extdata: [sal_kalem_detay, additional_fields],
    //    actions: ['G�nder'],
    //    parameters: [],
    //    attributes: {}
    //};

    import { mapGetters } from 'vuex';
    import dynoform from '@/components/DynoForm.vue';
    import grid from '@/components/grid.vue';
    import leftmenu from '@/components/leftmenu.vue';
    import kview from '@/components/view.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';
    import moment from 'moment';

    export default {
        name: 'gelenteklif',
        mixins: [FormMixIn],
        components: {
            dynoform,
            leftmenu,
            kview,
        },
        props: {
            //form: String,
            formid: [Number, String],
            view: String,
            drawer: { type: Boolean, default: true }
        },
        data: function ()
        {
            return {
                valid: false,
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
                viewName: '',
                actionResultDialog: false,
                validateError: '',
                username: '',
                form: '',
                formname: 'gelenteklif',
                openallpanel: [0],
                notification: false,
                currentform: 'gelenteklif',
                errors: [],
                activeCurrencies: [],
                currencyDateData: {},
                showDetailDialog : false
            };
        },
        methods: {
            FormLoaded: function ()
            {
                var currstate = this.GetFormState;
                this.SetFieldHidden('teklifRank');
                this.SetFieldHidden('teklif_rank');

                this.$store.commit('SetFormActions', []);
                if ('X12'.indexOf(currstate) != -1 && !this.actionBarWait)
                {
                    //sontarihvesaat
                    //if ('S'.indexOf(currstate) != -1 && !this.actionBarWait)
                    //{
                    //    this.$store.commit('SetFormActions', [{ label: 'AL_CancelForm', action: 'Iptal', type: 'external' }, { label: 'AL_Complete', action: 'Tamam', type: 'external' }]);
                    //}
                    if (parseInt(this.GetFormField('CanBid')) == 1)
                    {
                        if (parseInt(this.GetFormField('katilmayacak')) == 1) {
                            this.$store.commit('SetFormActions', [{ label: 'AL_Katilacak', action: 'katilacagim', type: 'external' }]);
                        }
                        else {
                            this.$store.commit('SetFormActions', [{ label: 'AL_Katilmayacak', action: 'katilmayacagim', type: 'external' }, { label: 'AL_Send', action: 'Tamam', type: 'external' }]);
                        }
                    }
                }

                if (this.GetFormField('teklifsontarihoncesiacilabilirmi') == '1') {
                    this.ClearFieldHidden('ihaleyontemi');
                }
                else {
                    this.SetFieldHidden('ihaleyontemi');
                }
            },
            ExtDataLoaded: function (table) {
                if (table == 'teklif_rank') {

                    if (this.GetFormField('ihaleyontemi') == '2' || this.GetFormField('ihaleyontemi') == '3') {
                        this.ClearFieldHidden('teklifRank');
                        this.SetFieldHidden('teklif_rank');

                        var teklifRank = this.GetExdataItems('teklif_rank');

                        var rank = 0;
                        if (teklifRank.length > 0) {
                            var rankItem = teklifRank.filter(function (item) {
                                return item.own == 1;
                            });
                            if (rankItem.length == 1) {
                                rank = rankItem[0].rank;
                            }

                            var payload = {};
                            payload.teklifRank = rank;

                            this.$store.commit('SetFields', payload);

                            if (this.GetFormField('ihaleyontemi') == '3') {
                                this.ClearFieldHidden('teklif_rank');
                            }
                        }
                        else {
                            this.SetFieldHidden('teklifRank');
                            this.SetFieldHidden('teklif_rank');
                        }
                    }
                    else {
                        this.SetFieldHidden('teklifRank');
                        this.SetFieldHidden('teklif_rank');
                    }
                }
                else if (table == 'sal_kalem_detay') {
                    this.checkKalemTableAndFilterKurlar();
                }
                else if (table == 'sal_kurlar') {
                    setTimeout(() => {
                        this.FilterCurrenciesData();
                        this.UpdateTotal();
                    }, 1000);
                }
            },
            NeedApprove: function (action)
            {
                var res;
                switch (action)
                {
                    case "Tamam":
                        res = '';
                        break;
                    case "Gericek":
                        res = this.$root.$i18n.t('DM_RejectionConfirmation');
                        break;
                    case "Iptal":
                        res = this.$root.$i18n.t('DM_CancelForm');
                        break;
                    default:
                        res = '';
                }
                return res;
            },
            Selected: function ()
            {
                return;
            },

            GetDetailViewFormView: function () {
                return detailView_formInfo;
            },
            FormButtonClicked(col)
            {
                var name = col.name;
                //if (name == 'ShowRequest') {
                //    //Request formunu g�ster
                //    var url = '/kforms/saltalep/saltalep_frm.aspx?openformid=' + this.GetFormField('RequestId');
                //    window.open(url);
                //}
            },

            RowClicked: function (rowdata, table)
            {
                return;
            },

            RowButtonClicked: function (payload)
            {
                if (payload.button.name == "url") {
                    var openUrl = '/api/showfile?file=' + payload.item.url;
                    window.open(openUrl, '_blank');
                }
                else if (payload.button.name == "copy")
                {
                    this.copyKalemRow(payload);
                }
                else if (payload.button.name == "delete") {
                    this.deleteKalemRow(payload);
                }
                else {
                    this.OpenDynamicForm(payload);
                }
            },
            copyKalemRow: function (payload) {
                var GetExdataColumns = this.GetExdataColumns('sal_kalem_detay');
                var GetExdataItems = this.GetExdataItems('sal_kalem_detay');

                if (payload != undefined && payload.item != undefined && payload.item.id != undefined) {
                    var cloned = {};
                    Object.assign(cloned, payload.item);
                    cloned.id = '';
                    cloned.rowid = GetExdataItems.length;
                    this.AddRow2ExtData(cloned, "sal_kalem_detay");
                }
            },
            deleteKalemRow: function (payload) {
                var GetExdataColumns = this.GetExdataColumns('sal_kalem_detay');
                var GetExdataItems = this.GetExdataItems('sal_kalem_detay');

                if (payload != undefined && payload.item != undefined) {
                    var indexToRemove = GetExdataItems.findIndex(function (item) {
                        return item.rowid === payload.item.rowid;
                    });

                    // If the item is found, remove it using splice
                    if (indexToRemove !== -1) {
                        var payloadInner = {};
                        if (payloadInner.conversionid == '' || payloadInner.conversionid == undefined) {
                            payloadInner.conversionid = payload.item.id;
                        }
                        else {
                            payload.conversionid + ',' + payload.item.id
                        }

                        this.$store.commit('SetFields', payloadInner);

                        GetExdataItems.splice(indexToRemove, 1);
                    }
                }
            },
            OpenDynamicForm: function (payload) {
                if (payload != undefined && payload.item != undefined && payload.item.id != undefined) {

                    var exdataItems = this.GetExdataItems('additional_fields');

                    var fields = exdataItems.filter(function (item) {
                        return payload.item.id == item.Itemid;
                    });

                    var setFieldPayload = {};

                    setFieldPayload.form = 'dynamicFormData';
                    setFieldPayload.id = payload.item.id;
                    for (var i = 0; i < fields.length; i++) {
                        var field = fields[i];
                        setFieldPayload[field.id.toString()] = field.PropValue;
                    }
                    this.$store.commit('SetFields', setFieldPayload);

                    dynamic_formInfo.Columns = {};
                    dynamic_formInfo.Columns.id = dynamic_formInfo.InitialColumns.id;
                    for (var i = 0; i < fields.length; i++) {
                        var field = fields[i];
                        var definition =
                        {
                            type: field.PropType,
                            group: 1,
                            sira: 1,
                            name: field.id,
                            label: field.PropName,
                            edit: '012X',
                            col: '6'
                        };
                        dynamic_formInfo.Columns[field.id.toString()] = definition;
                    }

                    this.showDetailDialog = true;
                }
            },
            UpdateDynamicForm: function () {

                var formdata = this.GetFormDataFunction("dynamicFormData");
                var exdataItems = this.GetExdataItems('additional_fields');

                for (var i = 0; i < exdataItems.length; i++) {
                    var field = exdataItems[i];
                    if (field.Itemid == formdata.id) {
                        for (var key in formdata) {
                            if (field.id == key && key != 'form' && key != 'id') {
                                field.PropValue = formdata[key];
                            }
                        }
                    }
                }

                this.showDetailDialog = false;
            },

            ChangeAttribute: function (attribute, tableName)
            {
                var payload = { ...attribute, tableName };
                Utilities.log("Grid Emit changeAttribute", payload);
                this.$store.commit('SetFieldAttributes', payload);
            },
            ValidateForm(action)
            {
                this.ClearFieldErrors();
                var errors = [];
                var result = "";

                var columns = this.GetFormColumns;
                var formdata = this.GetFormData;
                var attributes = this.GetFormAttributes;
                var GetExdataColumns = this.GetExdataColumns('sal_kalem_detay');
                var GetExdataItems = this.GetExdataItems('sal_kalem_detay');

                var additionalFieldsExdataColumns = this.GetExdataColumns('additional_fields');
                var additionalFieldsExdataItems = this.GetExdataItems('additional_fields');

                if (action.action == "katilmayacagim" || action.action == "katilacagim") {
                    if (action.action == "katilmayacagim")
                    {
                        var neden = formdata.ihalekatilmamaneden;
                        if (!neden) neden = "";

                        var value = neden.toString().trim();
                        if (value == "")
                        {
                            var err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_TenderNonParticipationReason') });
                            errors.push(err);
                            this.SetFieldError("ihalekatilmamaneden");
                            result = errors;
                        }
                    }

                    return result;
                }
                var escapeFields = ['id', 'kformsid'];

                for (var c in columns) {
                    if (formdata[c] == null) formdata[c] = "";

                    var value = formdata[c].toString().trim();
                    var column = columns[c];
                    var attr = attributes[c];
                    var err = '';

                    if (attr.disabled == false) {
                        if (!escapeFields.includes(column.name)) {
                            err = this.ValidateField(value, column);
                        }

                        if (err != '') {
                            errors.push(err);
                            this.SetFieldError(c);
                        }
                    }
                }

                var allZero = true;
                for (var i = 0; i < GetExdataItems.length; i++) {
                    for (var col in GetExdataColumns) {
                        if (GetExdataItems[i][col] == undefined) GetExdataItems[i][col] = "";

                        var value = GetExdataItems[i][col].toString().trim();
                        var column = GetExdataColumns[col];
                        var err = this.ValidateField(value, column);

                        if (col == 'alisfiyat') {
                            if (value != 0) {
                                allZero = false;
                            }
                        }
                        var id = GetExdataItems[i]['rowid'];
                        if (err != '') {
                            errors.push(err);
                            this.SetExtDataFieldError('sal_kalem_detay', id, col);
                        }
                        else {
                            this.ClearExtDataFieldError('sal_kalem_detay', id, col);
                        }
                    }
                }
                if (allZero) {
                    errors.push('En az bir kaleme fiyat girmelisiniz.');
                    this.SetFieldError('sal_kalem_detay');
                }

                var exdataItems = this.GetExdataItems('gelenteklif_tedarikci_files');
                var exdataColumns = this.GetExdataColumns('gelenteklif_tedarikci_files');
                for (var i = 0; i < exdataItems.length; i++)
                {
                    if (!exdataItems[i]["aciklama"] || exdataItems[i]["aciklama"].toString().trim() == "")
                    {
                        var value = exdataItems[i]["aciklama"].toString().trim();
                        var column = exdataColumns["aciklama"];
                        var err = this.ValidateField(value, column);
                        errors.push(err);
                        var id = exdataItems[i]['rowid'];
                        this.SetExtDataFieldError('gelenteklif_tedarikci_files', id, "aciklama");
                    }
                    else
                    {
                        this.ClearExtDataFieldError('gelenteklif_tedarikci_files', id, "aciklama");
                    }

                    if (!exdataItems[i]["url"] || exdataItems[i]["url"].toString().trim() == "")
                    {
                        var value = exdataItems[i]["url"].toString().trim();
                        var column = exdataColumns["url"];
                        var err = this.ValidateField(value, column);
                        errors.push(err);
                        var id = exdataItems[i]['rowid'];
                        this.SetExtDataFieldError('gelenteklif_tedarikci_files', id, "url");
                    }
                    else
                    {
                        this.ClearExtDataFieldError('gelenteklif_tedarikci_files', id, "url");
                    }
                }

                for (var i = 0; i < additionalFieldsExdataItems.length; i++) {
                    if (additionalFieldsExdataItems[i]["PropValue"] == undefined) additionalFieldsExdataItems[i]["PropValue"] = "";

                    var value = additionalFieldsExdataItems[i]["PropValue"].toString().trim();
                    var column = additionalFieldsExdataColumns["PropValue"];
                    column.label = additionalFieldsExdataItems[i]["PropName"];
                    column.type = additionalFieldsExdataItems[i]["PropType"];
                    var err = this.ValidateField(value, column);

                    var id = additionalFieldsExdataItems[i]['rowid'];
                    if (err != '') {
                        errors.push("Kalem ek bilgilerindeki " + err);
                        this.SetExtDataFieldError('additional_fields', id, "PropValue");
                    }
                    else {
                        this.ClearExtDataFieldError('additional_fields', id, "PropValue");
                    }
                }

                if (action.action == "Gericek" || action.action == "Iptal")
                {
                    if (!formdata.oonaynot)
                    {
                        var err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_Note') });
                        errors.push(err);
                        this.SetFieldError("oonaynot");
                    }
                }
                if (errors.length > 0)
                {
                    result = errors;
                }
                return result;
            },
            LoadNewForm() {
                var emptyForm = {};
                emptyForm.state = '0';                

                this.$store.commit("ClearForm");
                this.$store.commit('SetFormData', emptyForm);

                var payload = {};
                payload.teklifisteformno = this.$route.query.teklifId;

                this.$store.commit('SetFields', payload);

                this.$store.dispatch("GetExtData", 'sal_kalem_detay')
                    .then(response => {
                        Utilities.log("Got Extdata", response);
                    })
                    .catch(err => {
                        Utilities.log("Hata:", err);
                    });

                this.$store.dispatch("GetExtData", 'additional_fields')
                    .then(response => {
                        Utilities.log("Got Extdata", response);
                    })
                    .catch(err => {
                        Utilities.log("Hata:", err);
                    });

                payload = {};
                payload.action = "GetTeklifIsteForm"
                payload.data = [{ "formid": this.$route.query.teklifId }];
                this.$store.commit("SetFormLoadingStatus", true);
                this.$store.dispatch("RunTime", payload)
                    .then(response => {
                        this.$store.commit("SetFormLoadingStatus", false);
                        var formData = response.data.runTimeResult[0];

                        payload = {};
                        payload.aciklamalar = formData.aciklamalar;
                        payload.konu = formData.konu;
                        payload.administrativeSpecificationFile = formData.administrativeSpecificationFile;
                        payload.ihaleyontemi = formData.ihaleyontemi;
                        payload.sonsaat = formData.sonsaat;
                        payload.sontarih = formData.sontarih;
                        payload.technicalSpecificationFile = formData.technicalSpecificationFile;
                        payload.teklifsontarihoncesiacilabilirmi = formData.teklifsontarihoncesiacilabilirmi;
                        payload.teslimatadresi = formData.teslimatadresi;
                        payload.teslimtarihi = formData.teslimtarihi;
                        payload.formkey = formData.formkey;
                        payload.teklifisteformno = this.$route.query.teklifId;
                        payload.durum = this.$root.$i18n.t('ST_Creating');

                        this.$store.commit('SetFields', payload);

                        if (this.GetFormField('teklifsontarihoncesiacilabilirmi') == '1') {
                            this.ClearFieldHidden('ihaleyontemi');
                        }
                        else {
                            this.SetFieldHidden('ihaleyontemi');
                        }
                        this.SetFieldHidden('teklifRank');
                        this.SetFieldHidden('teklif_rank');

                        this.$store.commit('SetFormActions', [{ label: 'AL_Send', action: 'Tamam', type: 'external' }]);

                    });
            },

            sal_kalem_detay_Row_Changed: function (row) {

                var self = this;
                this.ChangeCurrencyRateWithCurrency(row.rowdata.doviz, function (result) {
                    row.rowdata.taleptutardoviz = row.rowdata.alisfiyat * row.rowdata.talepmiktar;
                    row.rowdata.dovizkur = result;
                    if (row.rowdata.dovizkur > 0) {
                        row.rowdata.taleptutar = row.rowdata.taleptutardoviz * row.rowdata.dovizkur;
                    }
                    else {
                        row.rowdata.taleptutar = row.rowdata.taleptutardoviz;
                    }
                    self.UpdateTotal();
                });
            },
            sal_kurlar_Row_Changed: function (row) {
                if (row.rowdata.tip == 'SBST') {
                    this.ClearExtDataFieldReadOnly('sal_kurlar', row.rowdata.rowid, 'dovizkur');
                }
                else {
                    this.SetExtDataFieldReadOnly('sal_kurlar', row.rowdata.rowid, 'dovizkur');
                    this.ChangeCurrencyRate(row.rowdata.rowid, function (result) { });
                }
                var doviz = this.activeCurrencies.join(', ');
                if (doviz == 'TL') {
                    this.SetFieldHidden('sal_kurlar');
                }
                else {
                    this.ClearFieldHidden('sal_kurlar');
                }
                this.UpdateTotal();
            },
            ChangeCurrencyRateWithCurrency: function (Currency, success) {

                if (Currency == 'TL') {
                    this.checkKalemTableAndFilterKurlar();
                    success(1);
                    return "";
                }
                var exdataItems = this.GetExdataItems('sal_kurlar');
                var self = this;
                var currencyFound = false;
                var currencyIndex = -1;

                for (var i = 0; i < exdataItems.length; i++) {
                    if (exdataItems[i].doviz === Currency) {
                        currencyFound = true;
                        currencyIndex = exdataItems[i].rowid;
                        break;
                    }
                }
                if (currencyFound) {
                    this.ChangeCurrencyRate(currencyIndex, success);
                    this.checkKalemTableAndFilterKurlar();
                }
                else {
                    this.GetCurrencyRateFromData(Currency, new Date(), function (data) {
                        var newCurrencyData = {
                            doviz: Currency,
                            dovizkur: data['TCAK'],
                            tip: 'TCAK',
                            rowid: exdataItems.length + 1,
                            tarih: self.getDateString(new Date()),
                            id:-1
                        };
                        exdataItems.push(newCurrencyData);

                        var payload = {};
                        payload.tableName = "sal_kurlar";
                        payload.data = exdataItems;
                        payload.maxLen = exdataItems.length;
                        self.$store.commit('SetExtdataData', payload);

                        self.checkKalemTableAndFilterKurlar();
                        success(data['TCAK']);

                    });
                }
            },
            checkKalemTableAndFilterKurlar: function () {
                var kalem_detay = this.GetExdata('sal_kalem_detay');

                this.activeCurrencies = [];
                for (var i = 0; i < kalem_detay.data.length; i++) {
                    if (this.activeCurrencies.indexOf(kalem_detay.data[i].doviz) > -1) continue;
                    this.activeCurrencies.push(kalem_detay.data[i].doviz);
                }

                if (this.activeCurrencies.length === 1 && this.activeCurrencies[0] === 'TL') {
                    this.SetFieldHidden('sal_kurlar');
                } else {
                    this.ClearFieldHidden('sal_kurlar');
                }

                this.FilterCurrenciesData();

                this.UpdateTotal();
            },
            ChangeCurrencyRate: function (rowid, success) {
                var index = rowid -1;
                var exdataItems = this.GetExdataItems('sal_kurlar');
                var self = this;
                this.GetCurrencyRateFromData(exdataItems[index].doviz, new Date(), function (data) {
                    exdataItems[index].dovizkur = data[exdataItems[index].tip];

                    var payload = {};
                    payload.tableName = "sal_kurlar";
                    payload.data = exdataItems;
                    payload.maxLen = exdataItems.length;
                    self.$store.commit('SetExtdataData', payload);
                    success(exdataItems[index].dovizkur);
                });
            },
            UpdateTotal: function () {
                var kalem_detay = this.GetExdata('sal_kalem_detay');
                var toplamtutar = 0, geneltoplam = 0, toplamkdv = 0, kdvharic = 0;

                for (var i = 0; i < kalem_detay.data.length; i++) {
                    var kalem = kalem_detay.data[i];
                    kalem.dovizkur = this.GetCurrencyRate(kalem.doviz);
                    //var tutar = this.GetCurrencyRate(kalem.doviz) * kalem.alisfiyat * kalem.talepmiktar;
                    //var kdvTutar = tutar * kalem.kdv / 100;
                    //geneltoplam += tutar;
                    //kdvharic += tutar;
                    //toplamkdv += kdvTutar;
                    //toplamtutar += tutar + kdvTutar;
                }

                var payload = {};
                payload.tableName = "sal_kalem_detay";
                payload.data = kalem_detay.data;
                payload.maxLen = kalem_detay.data.length;
                this.$store.commit('SetExtdataData', payload);

            },
            FilterCurrenciesData: function () {

                var exdataItems = this.GetExdataItems('sal_kurlar');
                var extDataItemsFiltered = []
                for (var i = 0; i < exdataItems.length; i++) {
                    var field = exdataItems[i];
                    for (var j = 0; j < this.activeCurrencies.length; j++) {
                        if (field.doviz == this.activeCurrencies[j]) {
                            field.rowid = extDataItemsFiltered.length + 1;
                            extDataItemsFiltered.push(field);
                        }
                    }
                }
                for (var i = 0; i < extDataItemsFiltered.length; i++) {
                    var dateKey = extDataItemsFiltered[i].doviz + this.getDateString(new Date());
                    if (this.currencyDateData[dateKey] == undefined) {
                        this.LoadCurrencyRate(extDataItemsFiltered[i].doviz, this.getDateString(new Date()), dateKey);
                    }
                }

                var payload = {};
                payload.tableName = "sal_kurlar";
                payload.data = extDataItemsFiltered;
                payload.maxLen = extDataItemsFiltered.length;
                this.$store.commit('SetExtdataData', payload);

                for (var i = 0; i < extDataItemsFiltered.length; i++) {
                    if (extDataItemsFiltered[i].tip == 'SBST') {
                        this.ClearExtDataFieldReadOnly('sal_kurlar', extDataItemsFiltered[i].rowid, 'dovizkur');
                    }
                    else {
                        this.SetExtDataFieldReadOnly('sal_kurlar', extDataItemsFiltered[i].rowid, 'dovizkur');
                    }
                }
            },
            GetCurrencyRate: function (currency) {

                var exdataItems = this.GetExdataItems('sal_kurlar');
                var rate = 1;
                for (var i = 0; i < exdataItems.length; i++) {
                    var field = exdataItems[i];

                    if (field.doviz == currency) {
                        rate = field.dovizkur;
                    }
                }

                return rate;
            },
            LoadCurrencyRate: function (currency, duzenlemeTarihi, dateKey, onSuccess) {
                var payload = {};
                payload.action = "GetCurrencyRate"
                payload.data = [{ "Currency": currency, "Date": duzenlemeTarihi }];
                var self = this;
                this.$store.dispatch("RunTime", payload)
                    .then(response => {
                        Utilities.log("Runtime  success result:", response);
                        self.currencyDateData[dateKey] = response.data.runTimeResult[0];
                        onSuccess(self.currencyDateData[dateKey]);
                    })
                    .catch(err => {
                        Utilities.log("Runtime erroro result:", err);

                    });

            },
            GetCurrencyRateFromData: function (currency, duzenlemeTarihi, onSuccess) {
                    
                var dateKey = currency + this.getDateString(duzenlemeTarihi);
                var self = this;
                if (this.currencyDateData[dateKey] == undefined) {
                    this.LoadCurrencyRate(currency, duzenlemeTarihi, dateKey, function () {
                        onSuccess(self.currencyDateData[dateKey]);
                    });
                }
                else {
                    onSuccess(this.currencyDateData[dateKey]);
                }
            },
            getDateString: function (date) {
                var _date = new Date(date);

                return _date.getDate() + "/" + (_date.getMonth() + 1) + "/" + _date.getFullYear();
            },
        },
        created: function ()
        {
            sal_kalem_detay.columns.doviz.options = [
                { text: this.$root.$i18n.t('OP_PleaseSelect'), value: '' },
                { text: "TL", value: "TL" },
                { text: "USD", value: "USD" },
                { text: "EUR", value: "EUR" },
                { text: "GBP", value: "GBP" }
            ];

            GelenTeklif_formInfo.Columns.teklifsontarihoncesiacilabilirmi.options = [
                { text: this.$root.$i18n.t('AL_OpenTendering'), value: '1' },
                { text: this.$root.$i18n.t('AL_SealedTendering'), value: '0' }
            ];

            GelenTeklif_formInfo.Columns.ihaleyontemi.options = [
                { text: this.$root.$i18n.t('AL_OnlyProcurement'), value: '1' },
                { text: this.$root.$i18n.t('AL_OnlySorting'), value: '2' },
                { text: this.$root.$i18n.t('AL_SortingAndTitle'), value: '3' }
            ];

            sal_kurlar.columns.tip.options = [
                { text: "TCMB Doviz Alis", value: "TCAK" },
                { text: "TCMB Doviz Satis", value: "TCSK" },
                { text: "Gise Doviz Alis", value: "AGAK" },
                { text: "Gise Doviz Satis", value: "AGSK" },
                { text: "Sabit Kur", value: "SBST" }
            ];
        },
        mounted: function ()
        {
            this.openallpanel = [0];
            this.$store.commit('updateFormInfo', GelenTeklif_formInfo);
            var form = GelenTeklif_formInfo.form;
            var whatToShow = this.$route.name.replace(form, '');
            this.$store.commit('SetWhatToShow', { show: whatToShow });
            if (whatToShow == 'form')
            {
                var params = this.$route.params;
                if (params != undefined && params.id != undefined) {
                    if (Number(params.id) > 0) {
                        var formid = Number(params.id);

                        if (formid != undefined && formid != 0) {
                            this.$store.commit('SetFormAndFormId', {
                                form: form,
                                formid: formid
                            });
                            this.LoadFormData(formid);
                        }
                    }
                    else {
                        this.$store.commit('SetFormAndFormId', {
                            form: form,
                            formid: 0
                        });
                        this.LoadNewForm();
                    }
                }
            }
            else if (whatToShow == 'yeni')
            {
                this.$store.commit('SetFormAndFormId', {
                    form: form,
                    formid: 0
                });
                this.LoadFormData(0);
            } else
            {
                this.$store.commit('SetWhatToShow', { show: whatToShow });
            }
            var self = this;
            this.$store.dispatch('GetUserInfo').then(userInfo =>
            {
                self.username = userInfo.data.AdSoyad;
            });
        },
        computed: {
            FormAttributes: function ()
            {
                return this.GetFormAttributes;
            },
            UserName()
            {
                return this.GetUserName;
            },
            FormLoadingStatus: {
                get: function ()
                {
                    return this.GetFormLoadingStatus;
                }
            },
            DynamicFormInfo: function () {
                return dynamic_formInfo;
            }
        },
        watch: {
            FormLoadingStatus: {
                handler: function (val, oldVal)
                {
                    console.log('FormLoadingStatus changed');
                },
                deep: true
            },
            FormAttributes: function (nval, oldval)
            {
                return;
            },
            $route: function (nval, oval)
            {
                console.log('WATCH Route in gelenteklif :', nval, ' form:', this.formname);
                console.log('WATCH Route  in gelenteklif this:', this.$route);
                var form = GelenTeklif_formInfo.form;
                var whatToShow = this.$route.name.replace(form, '');
                this.$store.commit('SetWhatToShow', { show: whatToShow });
                if (whatToShow == 'form')
                {
                    var params = this.$route.params;
                    if (params != undefined && params.id != undefined)
                    {
                        if (Number(params.id) > 0) {
                            var formid = Number(params.id);

                            if (formid != undefined && formid != 0) {
                                this.$store.commit('SetFormAndFormId', {
                                    form: form,
                                    formid: formid
                                });
                                this.LoadFormData(formid);
                            }
                        }
                        else {
                            this.$store.commit('SetFormAndFormId', {
                                form: form,
                                formid: 0
                            });
                            this.LoadNewForm();
                        }
                    }
                }
                else if (whatToShow == 'yeni')
                {
                    this.$store.commit('SetFormAndFormId', {
                        form: form,
                        formid: 0
                    });
                    this.LoadFormData(0);
                } else
                {
                    this.$store.commit('SetWhatToShow', { show: whatToShow });
                }
            },
            drawer: function (n, o)
            {
                return;
            },

            notification: function (n, o)
            {
                this.$emit("ChangeNotifiction",
                    { validateError: this.validateError, notification: this.notification });
            },

            validateError: function (n, o)
            {
                this.$emit("ChangeNotifiction",
                    { validateError: this.validateError, notification: this.notification });
            }
        }
    };
</script>
